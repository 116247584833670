import React from "react";
import "./styles.css";

function CircleLoder({ className }) {
  return (
    <div
      className={`loader-5 center before:bg-slate-600 after:bg-slate-600 ${className}`}
    >
      <span className="before:bg-slate-600 after:bg-slate-600"></span>
    </div>
  );
}

export default CircleLoder;
