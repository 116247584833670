import React, { useState } from "react";
import "./styles.css";
import { MdShare } from "react-icons/md";
import { FaGavel } from "react-icons/fa";
import Logo from "../../assets/images/handymann_logo.png";
import Nav from "./Nav";
import Button from "../button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "../modal/Modal";
import { LINKS } from "../../constants/data";

function Header() {
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  function toggleShowModal() {
    setShowModal(!showModal);
  }

  function handleShareButtonClick(item) {
    if (!item.name.toLocaleLowerCase().includes("copy")) {
      let url = item.baseApi + item.url;
      window.open(url, "_blank");
    } else {
      navigator.clipboard.writeText(item.url);
    }
    toggleShowModal();
  }

  return (
    <div id="header-container" className="wrapper full">
      <header className="wrapper auto">
        <div
          id="header-left"
          className="flex pointer"
          onClick={() => navigate("/")}
        >
          <div className="logo-wrapper">
            <img src={Logo} alt="" id="logo-main" />
            {location.pathname.includes("policies") && (
              <div className="icon">
                <FaGavel />
              </div>
            )}
          </div>
          <h3 className="logo-text">
            HandyMann {location.pathname.includes("policies") && "Policies"}
          </h3>
        </div>
        <div id="header-center" className="flex mg-l-auto">
          <Nav />
        </div>
        {!location.pathname.includes("policies") && (
          <>
            <div id="header-right" className="flex">
              <Button
                // className={"icon-btn"}
                text="Share"
                icon={<MdShare size={18} />}
                styles={{
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white-color)",
                }}
                onClick={toggleShowModal}
              />
            </div>
          </>
        )}
      </header>
      {!location.pathname.includes("policies") && (
        <Modal
          visible={showModal}
          onClose={toggleShowModal}
          title={"Share HandyMann with your friends"}
          subTitle={
            "Help your friends join the waiting list! Use any of the options below to share HandyMann with your friends and family."
          }
          content={
            <div className="flex justify-center gap-3">
              {LINKS.map((item, index) => {
                return (
                  <Button
                    onClick={() => handleShareButtonClick(item)}
                    key={index}
                    icon={item.icon}
                    className={"icon-btn"}
                    title={item.name}
                    styles={{
                      backgroundColor: item.name
                        .toLocaleLowerCase()
                        .includes("copy")
                        ? "var(--grey-light-color)"
                        : "var(--primary-color)",
                      color: item.name.toLocaleLowerCase().includes("copy")
                        ? "var(--text-primary)"
                        : "var(--white-color)",
                    }}
                    iconStyles={{
                      fontSize: "22px",
                      alignItems: "center",
                    }}
                  />
                );
              })}
            </div>
          }
        />
      )}
    </div>
  );
}

export default Header;
