import React from "react";

export default function Modal({
  visible,
  onClose,
  contentClassName,
  title,
  subTitle,
  content,
  footerText,
}) {
  // Get the modal
  var modal = document.getElementById("modal");

  // When the user clicks anywhere outside of the modal, close it
  window.onclick = function (event) {
    if (event.target === modal && onClose) {
      modal.style.display = "none";
      onClose(false);
    }
  };

  function handleOnClose() {
    if (onClose) {
      onClose(false);
    }
  }

  return (
    <div
      id="modal"
      className="modal"
      style={{ display: visible ? "flex" : "none" }}
    >
      <div className={`modal-content ${contentClassName}`}>
        {onClose && (
          <div className="close" onClick={handleOnClose}>
            &times;
          </div>
        )}
        {title && <h3 className="title">{title}</h3>}
        {subTitle && <p className="sub-title">{subTitle}</p>}
        <div className="modal-content-inner">{content}</div>
        <p className="text-sm text-slate-500">{footerText}</p>
      </div>
    </div>
  );
}
