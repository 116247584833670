import React from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import "./styles.css";
// import "./animations.css";

function QuestionsListItem({ title, body }) {
  // const [opened, setOpened] = useState(false);
  // function toggleOpened() {
  //   setOpened(!opened);
  // }

  function addTypedtextClassHandler(event) {
    for (const divs of document.querySelectorAll("div.typedtext")) {
      divs.classList.remove("typedtext");
    }
    if (event.currentTarget.classList.contains("typedtext")) {
      console.log(true);
      return event.currentTarget.classList.remove("typedtext");
    } else {
      console.log(false);
      return event.currentTarget.classList.add("typedtext");
    }
  }

  return (
    <div
      className="toggle-list-card pointer"
      onClick={addTypedtextClassHandler}
    >
      <div className="icon pointer">{<MdKeyboardArrowDown />}</div>
      <div className="content">
        <div className="head pointer">{title}</div>
        <div className="body">{body}</div>
      </div>
    </div>
  );
}

export default QuestionsListItem;
