import React, { useEffect } from "react";
import QuestionsListItem from "../../components/list/QuestionsListItem";
import { FAQ_DATA } from "../../constants/data";

function FAQ({ title }) {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = title;
  }, [title]);
  return (
    <div className="grid-page">
      <div>
        <h3>FAQ</h3>
      </div>
      <div className="page-content">
        <h4 style={{ marginTop: "0px" }}>Got some questions on your mind?</h4>
        <p>
          Don't worry, here are some answers to questions you might be asking.
        </p>
        {FAQ_DATA.map((item, index) => {
          return (
            <QuestionsListItem
              key={index}
              title={item.question}
              body={item.answer}
            />
          );
        })}
      </div>
    </div>
  );
}

export default FAQ;
