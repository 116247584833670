import React, { useEffect } from "react";

export default function TermsOfService({ title }) {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = title;
  }, [title]);

  return (
    <div className="grid-page">
      <div></div>
      <div className="page-content">
        <p style={{ marginTop: "0px" }}>
          Effective date:{" "}
          <strong>
            <em>23rd March, 2023</em>
          </strong>
        </p>
        <p>
          Thank you for choosing <strong>Handymann</strong>. Our App is designed
          to connect users with skilled and experienced handymen near them.
          These Terms of Service (“Terms”) govern your use of our App. By using
          our App, you agree to these Terms.
        </p>
        <div id="section-1">
          <h4>Use of Our App</h4>
          <p>
            You may use our App only for lawful purposes and in accordance with
            these Terms. You may not use our App:
          </p>
          <ul>
            <li>
              In any way that violates any applicable federal, state, local, or
              international law or regulation;
            </li>
            <li>
              For the purpose of exploiting, harming, or attempting to exploit
              or harm minors in any way by exposing them to inappropriate
              content, asking for personally identifiable information, or
              otherwise;
            </li>
            <li>
              To transmit, or procure the sending of, any advertising or
              promotional material, including any "junk mail," "chain letter,"
              "spam," or any other similar solicitation;
            </li>
            <li>
              To impersonate or attempt to impersonate us, our employees,
              another user, or any other person or entity;
            </li>
            <li>
              To engage in any other conduct that restricts or inhibits anyone's
              use or enjoyment of our App, or which, as determined by us, may
              harm us or users of our App, or expose them to liability.
            </li>
          </ul>
        </div>
        <div id="section-2">
          <h4>User Content</h4>
          <p>
            Our App may allow you to submit content, such as reviews, ratings,
            and comments (“User Content”). By submitting User Content, you grant
            us a non-exclusive, transferable, sub-licensable, royalty-free,
            worldwide license to use, copy, modify, create derivative works
            based on, distribute, publicly display, publicly perform, and
            otherwise exploit in any manner such User Content in all formats and
            distribution channels now known or hereafter devised (including in
            connection with our App and our business and on third-party sites
            and services), without further notice to or consent from you, and
            without the requirement of payment to you or any other person or
            entity.
          </p>
          <p>You represent and warrant that:</p>
          <ul>
            <li>
              You own or control all rights in and to the User Content and have
              the right to grant the license granted above;
            </li>
            <li>
              All of your User Content does and will comply with these Terms;
              and
            </li>
            <li>
              Your User Content does not violate any third-party right,
              including any copyright, trademark, patent, trade secret, moral
              right, privacy right, right of publicity, or any other
              intellectual property or proprietary right.
            </li>
          </ul>
        </div>
        <div id="section-3">
          <h4>Intellectual Property</h4>
          <p>
            Our App and its entire contents, features, and functionality
            (including but not limited to all information, software, text,
            displays, images, video, and audio, and the design, selection, and
            arrangement thereof) are owned by us, our licensors, or other
            providers of such material and are protected by United States and
            international copyright, trademark, patent, trade secret, and other
            intellectual property or proprietary rights laws.
          </p>
          <p>
            These Terms permit you to use our App for your personal,
            non-commercial use only. You must not reproduce, distribute, modify,
            create derivative works of, publicly display, publicly perform,
            republish, download, store, or transmit any of the material on our
            App, except as follows:
          </p>
          <ul>
            <li>
              Your computer may temporarily store copies of such materials in
              RAM incidental to your accessing and viewing those materials.
            </li>
            <li>
              You may store files that are automatically cached by your Web
              browser for display enhancement purposes.
            </li>
            <li>
              You may print or download one copy of a reasonable number of pages
              of our App for your own personal, non-commercial use and not for
              further reproduction, publication, or distribution.
            </li>
          </ul>
        </div>
        <div id="section-4">
          <h4>Disclaimer of Warranties</h4>
          <p>
            YOUR USE OF OUR APP, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED
            THROUGH OUR APP IS AT YOUR OWN RISK. OUR APP, ITS CONTENT, AND ANY
            SERVICES OR ITEMS OBTAINED THROUGH OUR APP ARE PROVIDED ON AN "AS
            IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND,
            EITHER EXPRESS OR IMPLIED. NEITHER WE NOR ANY PERSON ASSOCIATED WITH
            US MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
            COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
            AVAILABILITY OF OUR APP. WITHOUT LIMITING THE FOREGOING, NEITHER WE
            NOR ANYONE ASSOCIATED WITH US REPRESENTS OR WARRANTS THAT OUR APP,
            ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH OUR APP WILL
            BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS
            WILL BE CORRECTED, THAT OUR APP OR THE SERVER THAT MAKES IT
            AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT
            OUR APP OR ANY SERVICES OR ITEMS OBTAINED THROUGH OUR APP WILL
            OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
          </p>
          <p>
            WE DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
            STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES
            OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR
            PURPOSE.
          </p>
        </div>
        <div id="section-5">
          <h4>Limitation of Liability</h4>
          <p>
            IN NO EVENT WILL WE, OUR AFFILIATES, LICENSORS, SERVICE PROVIDERS,
            EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF
            ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION
            WITH YOUR USE, OR INABILITY TO USE, OUR APP, ANY WEBSITES LINKED TO
            IT, ANY CONTENT ON OUR APP OR SUCH OTHER WEBSITES, INCLUDING ANY
            DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE
            DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
            SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS,
            LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF
            GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING
            NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
          </p>
        </div>
        <div id="section-6">
          <h4>Indemnification</h4>
          <p>
            You agree to defend, indemnify, and hold harmless us, our
            affiliates, licensors, and service providers, and our and their
            respective officers, directors, employees, contractors, agents,
            licensors, suppliers, successors, and assigns from and against any
            claims, liabilities, damages, judgments, awards, losses, costs,
            expenses, or fees (including reasonable attorneys' fees) arising out
            of or relating to your violation of these Terms or your use of our
            App, including, but not limited to, your User Content, any use of
            our App's content, services, and products other than as expressly
            authorized in these Terms or your use of any information obtained
            from our App.
          </p>
        </div>
        <div id="section-7">
          <h4>Termination</h4>
          <p>
            We may terminate or suspend your access to all or part of our App,
            without notice or liability, for any reason, including if you
            violate these Terms. Upon termination, you must immediately stop
            using our App.
          </p>
        </div>
        <div id="section-8">
          <h4>Governing Law and Jurisdiction</h4>
          <p>
            These Terms and your use of our App shall be governed by and
            construed in accordance with the laws of the State of Ghana, without
            giving effect to any choice or conflict of law provision or rule.
            Any legal suit, action, or proceeding arising out of, or related to,
            these Terms or our App shall be instituted exclusively in the
            federal courts of the United States or the courts of the State of
            Ghana, in each case located in the City of Accra, and you waive any
            objection to the jurisdiction of such courts.
          </p>
        </div>
        <div id="section-9">
          <h4>Entire Agreement and Severability</h4>
          <p>
            These Terms and our Privacy Policy constitute the entire agreement
            between you and us regarding the use of our App. If any provision of
            these Terms is held to be invalid or unenforceable, the remaining
            provisions shall continue to be valid and enforceable to the fullest
            extent permitted by law.
          </p>
        </div>
        <div id="section-10">
          <h4>Waiver</h4>
          <p>
            No waiver of any term of these Terms shall be deemed a further or
            continuing waiver of such term or any other term, and our failure to
            assert any right or provision under these Terms shall not constitute
            a waiver of such right or provision.
          </p>
        </div>
        <div id="section-11">
          <h4>Assignment</h4>
          <p>
            You may not assign these Terms or any of your rights or obligations
            hereunder without our prior written consent, and any attempted
            assignment without such consent shall be null and void. We may
            assign these Terms or any of our rights or obligations hereunder
            without your prior written consent.
          </p>
        </div>
        <div id="section-11">
          <h4>Changes to these Terms</h4>
          <p>
            We reserve the right to modify these Terms at any time in our sole
            discretion. Your continued use of our App after any such
            modifications shall constitute your acceptance of these modified
            Terms.
          </p>
        </div>
        <div id="section-11">
          <h4>Contact Information</h4>
          <p>
            If you have any questions or comments about these Terms or our App,
            please contact us at{" "}
            <strong>
              <em>team.handymann@gmail.com</em>
            </strong>
          </p>
        </div>
        <p></p>
        <p>
          Thank you for choosing Handymann. We hope you find our services
          helpful and we appreciate your business.
        </p>
      </div>
    </div>
  );
}
