import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Updates({ title }) {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = title;
  }, [title]);
  return (
    <div className="grid-page">
      <div>
        <h3>Updates</h3>
      </div>
      <div className="page-content">
        <h4 style={{ marginTop: "0px" }}>What's new?</h4>
        <p>
          Welcome the the updates section of <strong>HandyMann</strong>! We are
          thrilled to have you here and can't wait to share all the latest news
          and developments with you as we work towards launching our app.
        </p>
        <p>
          As you may know, <strong>HandyMann</strong> is an app that will
          connect users to skilled and experienced handymen for a variety of
          services. Our app will provide an easy and convenient way for users to
          find reliable handymen in their area, get quotes, and schedule
          appointments too, all in just a few clicks.
          <br />
        </p>
        <p>
          Read more about <strong>HandyMann</strong> on our{" "}
          <span className="link pointer" onClick={() => navigate("/")}>
            About Us page
          </span>
          .
        </p>
        <h4>Where we are</h4>
        <p>
          We're currently in the development phase of the app and making great
          progress. Our team of developers and designers are working tirelessly
          to create a user-friendly interface, efficient communication system,
          and secure payment platform. We're also testing the app rigorously to
          ensure that it meets our high standards for quality and reliability.
        </p>
        <h4>Wait! There's more!</h4>
        <p>
          In addition to app development updates, we'll use this section to
          share news about our launch timeline, marketing efforts, and any
          partnerships we may form along the way. We believe that transparency
          and open communication are key to building a successful product, and
          we want to keep our waiting list subscribers informed every step of
          the way.
        </p>
        <p></p>
        <p>
          Thank you for your interest in HandyMann! We're excited to bring our
          app to market and help users tackle all their home maintenance needs
          with ease. Stay tuned for more updates, and feel free to reach out if
          you have any questions or feedback.
        </p>
      </div>
    </div>
  );
}

export default Updates;
