import { useNavigate, useRouteError } from "react-router-dom";
import "./styles.css";
import _404 from "../../assets/animations/404-anim.gif";
import { useEffect } from "react";

export default function Page404({ title }) {
  const error = useRouteError();
  // console.error(error);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = title;
  }, [title]);

  return (
    <div id="error-page">
      <div className="_404_image">
        <img
          src={_404}
          alt="Page not found!"
          title="Page not found!"
          style={{ width: "100%" }}
        />
      </div>
      <h1>Oops!</h1>
      <p>
        {(error.statusText?.toLowerCase() || error.message?.toLowerCase()) ===
        "not found"
          ? `The page you are requesting for
        does not exist or may have been moved to a different location.`
          : "Sorry, an unexpected error has occurred. "}
      </p>
      <p>
        While you are still here, checkout our{" "}
        <span className="link pointer" onClick={() => navigate("/services")}>
          amazing services we offer
        </span>{" "}
        our users, or get to know more{" "}
        <span className="link pointer" onClick={() => navigate("/")}>
          about us
        </span>
        .
      </p>
    </div>
  );
}
