import React from "react";

function Input({ className, onChange, value, placeholder, name, id }) {
  return (
    <div className={`relative mt-2 shadow-sm ${className}`}>
      <input
        type="text"
        name={name}
        id={id}
        value={value}
        onChange={(e) => onChange(e.target.value?.toString())}
        className="block w-full border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:outline-0 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
        placeholder={placeholder}
      />
    </div>
  );
}

export default Input;
