import { collection, addDoc, getDocs, query, where } from "@firebase/firestore";
import { db } from "../config";

function ADD_DOC(collectionName, data, loading, onDone, onError) {
  const ref = collection(db, collectionName);
  try {
    addDoc(ref, data);
    if (onDone) {
      onDone();
      loading(false);
    }
  } catch (error) {
    loading(false);
    if (onError) {
      onError(error);
    }
  }
}

async function CHECK_EXISTS(collectionName, whereArgs, onExists, onNotExists) {
  const ref = collection(db, collectionName);
  try {
    const q = query(ref, where(whereArgs[0], whereArgs[1], whereArgs[2]));

    const querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length > 0) {
      onExists();
    } else {
      onNotExists();
    }
  } catch (error) {
    console.log(error);
  }
}

export { ADD_DOC, CHECK_EXISTS };
