import React from "react";
import "./styles.css";

export default function ItemSquareCard({
  icon,
  title,
  description,
  onClick,
  active,
}) {
  return (
    <div
      className={`flex pointer item-square-card ${active && "active"}`}
      onClick={onClick}
    >
      <div className="icon">{icon}</div>
      <div className="content">
        <h4 className="item-title">{title}</h4>
        <p className="item-desc">{description}</p>
      </div>
    </div>
  );
}
