import React, { useEffect } from "react";

export default function PrivacyPolicy({ title }) {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = title;
  }, [title]);

  return (
    <div className="grid-page">
      <div></div>
      <div className="page-content">
        <p style={{ marginTop: "0px" }}>
          Effective date:{" "}
          <strong>
            <em>23rd March, 2023</em>
          </strong>
        </p>
        <p>
          Thank you for choosing <strong>Handymann</strong> ("App"). Our App is
          designed to connect users with skilled and experienced handymen near
          them. This Privacy Policy explains how we collect, use, disclose, and
          protect your personal information when you use our App.
        </p>
        <div id="section-1">
          <h4>Information We Collect</h4>
          <p>
            We may collect personal information from you, including your name,
            email address, phone number, and address. We may also collect
            information about your device and how you use our App.
          </p>
        </div>
        <div id="section-2">
          <h4>How We Use Your Information</h4>
          <p>We use your personal information to:</p>
          <ul>
            <li>Provide and improve our App;</li>
            <li>Communicate with you;</li>
            <li>Personalize your experience using our App;</li>
            <li>Provide customer support;</li>
            <li>
              Detect, prevent, and respond to fraud, abuse, or security
              incidents; and
            </li>
            <li>Comply with legal obligations</li>
          </ul>
        </div>
        <div id="section-3">
          <h4>How We Share Your Information</h4>
          <p>
            We may share your personal information with third parties as
            necessary to provide our App to you. For example, we may share your
            information with handymen near you in order to connect you with
            them. We may also share your information with our service providers
            who assist us in providing our App to you.
          </p>
          <p>
            We may also disclose your personal information if we believe that
            such disclosure is necessary to:
          </p>
          <ul>
            <li>Comply with applicable laws or regulations;</li>
            <li>Respond to a subpoena, court order, or other legal process;</li>
            <li>
              Protect the rights, property, or safety of our company, our
              customers, or others.
            </li>
          </ul>
        </div>
        <div id="section-4">
          <h4>Cookies and Tracking Technologies</h4>
          <p>
            We may use cookies and other tracking technologies to collect
            information about your use of our App. This information may include
            your device type, operating system, browser type, IP address, and
            other usage information. We use this information to improve our App
            and to personalize your experience using our App.
          </p>
        </div>
        <div id="section-5">
          <h4>Security</h4>
          <p>
            We take reasonable measures to protect your personal information
            from unauthorized access, disclosure, or destruction. However, no
            security system is completely secure, and we cannot guarantee the
            security of your personal information.
          </p>
        </div>
        <div id="section-6">
          <h4>Children's Privacy</h4>
          <p>
            Our App is not intended for children under the age of 13, and we do
            not knowingly collect personal information from children under the
            age of 13. If we learn that we have collected personal information
            from a child under the age of 13, we will take steps to delete such
            information.
          </p>
        </div>
        <div id="section-7">
          <h4>Changes to this Privacy Policy</h4>
          <p>
            We may update this Privacy Policy from time to time. If we make
            material changes to this Privacy Policy, we will notify you by
            posting a notice on our App or by other means.
          </p>
        </div>
        <div id="section-8">
          <h4>Contact Us</h4>
          <p>
            If you have any questions about this Privacy Policy or our App,
            please contact us at{" "}
            <strong>
              <em>team.handymann@gmail.com</em>
            </strong>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
