import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Nav({ classname }) {
  const navigate = useNavigate();
  const location = useLocation();
  function handleNavigation(route, state) {
    navigate(route, { state });
  }
  return (
    <ul id="nav-list" className={`flex ${classname}`}>
      <li
        className={`nav-item ${location.pathname === "/" && "active"}`}
        onClick={() => handleNavigation("/")}
      >
        About Us
      </li>
      {!location.pathname.includes("policies") && (
        <>
          <li
            className={`nav-item ${
              location.pathname === "/services" && "active"
            }`}
            onClick={() => handleNavigation("/services")}
          >
            Our Services
          </li>
          <li
            className={`nav-item ${
              location.pathname === "/updates" && "active"
            }`}
            onClick={() => handleNavigation("/updates")}
          >
            Updates
          </li>
          <li
            className={`nav-item ${location.pathname === "/faq" && "active"}`}
            onClick={() => handleNavigation("/faq")}
          >
            FAQ
          </li>
        </>
      )}
      {location.pathname.includes("policies") && (
        <li
          className={`nav-item ${location.pathname === "/faq" && "active"}`}
          onClick={() =>
            handleNavigation(
              location.pathname.includes("privacy-policy")
                ? "/policies/terms-of-service"
                : "/policies/privacy-policy"
            )
          }
        >
          {location.pathname.includes("privacy-policy")
            ? "Terms of Service"
            : "Privacy Policy"}
        </li>
      )}
    </ul>
  );
}

export default Nav;
