import React, { useEffect } from "react";
import ServicesListItem from "../../components/list/ServicesListItem";
import { SERVICES_DATA } from "../../constants/data";

function Services({ title }) {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = title;
  }, [title]);
  return (
    <div className="grid-page">
      <div>
        <h3>Our Services</h3>
      </div>
      <div className="page-content">
        <p>
          We are your One-stop Shop for Home, Office, and Outdoor Repairs and
          Maintenance!
        </p>
        <p>
          Our services cover a wide range of repair and maintenance for all your
          home, office, and outdoor needs. We pride ourselves on being a
          one-stop-shop for all your handyman needs, so you can rely on us to
          get the job done right.
        </p>
        <p>Our services includes;</p>
        <p></p>
        {SERVICES_DATA.map((item, index) => {
          return (
            <div key={index}>
              <h4 style={{ marginBottom: "15px" }}>{item.title}</h4>
              <div className="grid col-3">
                {item.categories.map((service, i) => {
                  return (
                    <ServicesListItem
                      key={i}
                      icon={service.icon}
                      title={service.name}
                      description={service.description}
                    />
                  );
                })}
              </div>
              {index !== SERVICES_DATA.length && (
                <div
                  style={{
                    width: "100%",
                    padding: "2px",
                    borderBottom: "1px solid var(--border-color)",
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Services;
