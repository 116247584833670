import React, { useEffect, useState } from "react";
import { MdOutlineEmail, MdPhone } from "react-icons/md";
import { HiArrowRight, HiChevronDown, HiChevronUp } from "react-icons/hi";
import { FaUser, FaUserTie } from "react-icons/fa";
import Button from "../button/Button";
import Modal from "../modal/Modal";
import ItemSquareCard from "../list/ItemSquareCard";
import { checkEmail, checkPhoneNumber } from "../../function/utils/formchecks";
import {
  ADD_DOC,
  CHECK_EXISTS,
} from "../../function/config/db/firebase/firestore";
import { LINKS } from "../../constants/data";
import SelectList from "../list/dropdown/SelectList";
import Input from "../input/input";
import CircleLoder from "../loaders/CirlceLoder";
import { UserIcon } from "@heroicons/react/20/solid";

const CATEGORIES = [
  "Electrical / Electronics",
  "Masonry",
  "Air Conditioning / Refrigeration",
  "Plumbing",
  "Welding / Metal Fabrication",
  "Painting / Skimming",
  "Tiling",
  "Plasterboard / POP Works",
  "Cleaning Service",
  "Gardening / Landscaping",
  "Car Detailing",
  "Auto Mechanic",
  "Steel Bending",
  "Carpentry / Furniture Works",
  "Fumigation / Pest Control",
  "Glazing / Glass works",
  "Other",
];

function Form() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [userType, setUserType] = useState("");
  const [handymanCategory, setHandymanCategory] = useState(CATEGORIES[0]);
  const [otherHandymanCategory, setOtherHandymanCategory] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [joined, setJoined] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleSetUserType(item) {
    setUserType(item);
    if (item !== "handyman") {
      setShowModal(false);
    }
  }

  function handleShareButtonClick(item) {
    if (!item.name.toLocaleLowerCase().includes("copy")) {
      let url = item.baseApi + item.url;
      window.open(url, "_blank");
    } else {
      navigator.clipboard.writeText(item.url);
    }
    setJoined(false);
  }

  function handleSubmit() {
    if (!name) return alert("Full name is required!");
    if (!phone) return alert("Phone number is required!");
    if (!userType)
      return alert(
        "User type required! Kindly select a user-type to continue."
      );
    if (userType === "handyman" && !handymanCategory) {
      return alert("service category before submitting.");
    }
    if (checkPhoneNumber(phone)) {
      setLoading(true);
      function onDone() {
        setLoading(false);
        setJoined(true);
        setPhone("");
        setUserType("");
        setHandymanCategory("");
      }
      function onNotExists() {
        ADD_DOC(
          "waiting-list",
          {
            name: name.trim(),
            phone: phone.trim(),
            userType,
            createdAt: `${new Date()}`,
            category: userType === "handyman" ? handymanCategory : "",
            otherCategory:
              userType === "handyman" && handymanCategory === "Other"
                ? otherHandymanCategory
                : "",
          },
          setLoading,
          onDone
        );
      }
      // check if exists
      CHECK_EXISTS(
        "waiting-list",
        ["phone", "==", phone.trim()],
        onDone,
        onNotExists
      );
    } else {
      alert(
        "Invalid phone number!\nPlease enter a valid 10-digit phone number."
      );
    }
  }

  return (
    <div id="form">
      <h2>Join our waiting list</h2>
      <p>Enter your full name</p>
      <div className="form-input-group mt-2 lg:w-5/6">
        <div className="flex left">
          <div className="email-input-container">
            <span className="icon text-slate-600">
              <UserIcon className="h-5 w-5" />
            </span>
            <input
              value={name}
              type="text"
              onChange={(e) => setName(e.target.value.toString())}
              placeholder="Your full name"
            />
          </div>
        </div>
      </div>
      <p>
        Provide your phone number and let us know how you intend to use
        HandyMann.
      </p>
      <div className="form-input-group lg:w-5/6 mt-2">
        <div className="flex left">
          <div className="email-input-container">
            <span className="icon">
              <MdPhone size={20} />
            </span>
            <div className="mr-2">
              <p className="text-sm">+233</p>
            </div>
            <input
              value={phone}
              type="phone"
              onChange={(e) => setPhone(e.target.value.toString())}
              placeholder="Phone number"
            />
          </div>
          <div
            className="flex justify-between pointer modal-btn"
            style={{
              fontSize: "14px",
              minWidth: "120px",
              borderLeft: "1px solid var(--border-color)",
              padding: " 0px 10px",
              color: userType
                ? "var(--text-primary)"
                : "var(--text-grey-color)",
              textTransform: "capitalize",
            }}
            onClick={() => setShowModal(true)}
          >
            <span>{userType || "User type..."}</span>{" "}
            {showModal ? <HiChevronUp /> : <HiChevronDown />}
          </div>
          <Button
            onClick={handleSubmit}
            className={"icon-btn"}
            icon={<HiArrowRight size={20} />}
            styles={{
              height: "45px",
              width: "45px",
              borderRadius: "0px 2px 2px 0px",
            }}
          />
        </div>
        {/* <CirlceLoder /> */}
      </div>
      <Modal
        visible={showModal || joined}
        onClose={() => (showModal ? setShowModal(false) : setJoined(false))}
        title={
          joined
            ? "Thank you for joining the waiting list!"
            : "Select Your User Type"
        }
        subTitle={
          joined
            ? "We are excited to have you as a potential user and we appreciate your interest in our services.\n Kindly share HandyMann with your friends and family using any of the following options."
            : "To provide you with tailored services that meet your needs, kindly select the option that best describes you."
        }
        content={
          joined ? (
            <div className="flex justify-center gap-3">
              {LINKS.map((item, index) => {
                return (
                  <Button
                    onClick={() => handleShareButtonClick(item)}
                    key={index}
                    icon={item.icon}
                    className={"icon-btn"}
                    title={item.name}
                    styles={{
                      backgroundColor: item.name
                        .toLocaleLowerCase()
                        .includes("copy")
                        ? "var(--grey-light-color)"
                        : "var(--primary-color)",
                      color: item.name.toLocaleLowerCase().includes("copy")
                        ? "var(--text-primary)"
                        : "var(--white-color)",
                    }}
                    iconStyles={{
                      fontSize: "22px",
                      alignItems: "center",
                    }}
                  />
                );
              })}
            </div>
          ) : (
            <>
              <div className="flex justify-center gap-3 gap-3">
                <ItemSquareCard
                  active={userType === "user"}
                  icon={<FaUser />}
                  title="I am a User"
                  description="Request Services"
                  onClick={() => handleSetUserType("user")}
                />
                <ItemSquareCard
                  active={userType === "handyman"}
                  icon={<FaUserTie />}
                  title={"I am a Handyman"}
                  description={"Provide Services"}
                  onClick={() => handleSetUserType("handyman")}
                />
              </div>
              {userType === "handyman" && (
                <>
                  <div style={{ marginTop: 30 }}></div>
                  <div className="w-60 mx-auto">
                    <SelectList
                      label="Choose a category:"
                      data={CATEGORIES}
                      selected={handymanCategory || CATEGORIES[0]}
                      setSelected={setHandymanCategory}
                      optionsClass="-mt-60"
                    />
                  </div>
                  {handymanCategory === "Other" && (
                    <Input
                      className="mt-6 w-60 mx-auto"
                      name="other-category"
                      value={otherHandymanCategory}
                      onChange={setOtherHandymanCategory}
                      placeholder="Add category..."
                    />
                  )}
                  {/* <select
                    id="serviceCategory"
                    name="serviceCategory"
                    style={{ padding: 10, borderRadius: "6px" }}
                    onChange={(e) => setHandymanCategory(e.target.value)}
                  >
                    <option value="" disabled selected>
                      Choose...
                    </option>
                    {CATEGORIES.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select> */}
                  {/* {
                    handymanCategory === "other" && {

                    }
                  } */}

                  {handymanCategory && (
                    <>
                      <div style={{ marginTop: 40 }}></div>
                      <Button
                        className="w-60 mx-auto"
                        styles={{
                          justifyContent: "center",
                        }}
                        text="Done"
                        disabled={
                          handymanCategory === "Other" && !otherHandymanCategory
                        }
                        onClick={() => setShowModal(false)}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )
        }
      />
      <Modal
        visible={loading}
        content={<CircleLoder className="mx-auto" />}
        footerText={"Submitting..."}
        contentClassName={"w-44 pt-5"}
      />
    </div>
  );
}

export default Form;
