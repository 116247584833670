import { Outlet, useLocation } from "react-router-dom";
import "./App.css";
import { MdDescription, MdPrivacyTip } from "react-icons/md";
import Lady_waiting from "./assets/animations/waiting-lady-green.gif";
import Form from "./components/form/Form";
import Header from "./components/header/Header";
import Nav from "./components/header/Nav";
import Footer from "./components/footer/Footer";

function App() {
  const location = useLocation();
  return (
    <div className="App">
      <Header />
      <Nav classname={"body"} />
      {location.pathname.includes("policies") ? (
        <div id="mast-head">
          <div
            id="mast-head-inner"
            className="flex justify-between wrapper auto"
          >
            <h1>
              {location.pathname.includes("privacy")
                ? "Our Privacy Policy"
                : "Our Terms Of Service"}
            </h1>
            <div className="mast-icon">
              {location.pathname.includes("privacy") ? (
                <MdPrivacyTip />
              ) : (
                <MdDescription />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="wrapper full">
          <div id="main-header" className="wrapper auto">
            <>
              <Form />
              <div className="header-anim">
                <img src={Lady_waiting} alt="anim" className="anim-banner" />
              </div>
            </>
          </div>
        </div>
      )}
      <div id="main-content" className="wrapper full">
        <div id="main-content-inner" className="wrapper auto">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
