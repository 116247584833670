import React, { useEffect } from "react";

function About({ title }) {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = title;
  }, [title]);
  return (
    <div className="grid-page">
      <div>
        <h3>About Us</h3>
      </div>
      <div className="page-content">
        <h4 style={{ marginTop: "0px" }}>Welcome!</h4>
        <p>
          Welcome to HandyMann, the app that connects users with reliable and
          trustworthy handymen and artisans at the click of a button.
        </p>
        <p>
          Our team is made up of experienced professionals who have years of
          experience in the industry and are passionate about providing
          exceptional customer service. We understand the frustrations of
          finding a reliable and trustworthy handyman or artisan, which is why
          we created our app to simplify the process for you.
        </p>
        <h4>HandyMann for Users</h4>
        <p>
          For normal users, the HandyMann app provides an easy-to-use platform
          that allows you to quickly schedule appointments, communicate with
          handymen, and receive quotes for the services you need. Our team of
          handymen are highly skilled and experienced in a variety of tasks,
          from minor repairs and maintenance to more complex installations and
          renovations. We only hire the best, so you can trust that your job
          will be completed to the highest standard.
        </p>
        <h4>HandyMann for Service Providers</h4>
        <p>
          For artisans, the HandyMann Business provides a platform to connect
          with potential clients and grow your business. Our app allows you to
          manage your appointments, track your earnings, and receive feedback
          from your clients. We believe in transparency and honesty and always
          aim to provide you with a fair and competitive commission for your
          services.
        </p>
        <h4>Why Choose Us?</h4>

        <p>
          At HandyMann, we take pride in our work and strive to exceed your
          expectations with every job we do. We are committed to providing you
          with high-quality services that are tailored to your specific needs,
          and we always aim to provide you with the best possible value for your
          money. We understand that your time is precious, which is why we have
          made it easy to schedule appointments and communicate with handymen
          and artisans directly through our app. With just a few clicks, you can
          quickly schedule your appointment, provide details about the job, and
          receive a quote for the work that needs to be done.
        </p>

        <p>
          We believe that transparency and honesty are essential in building a
          lasting relationship with our clients and artisans, which is why we
          provide clear and upfront pricing for all our services. We do not
          charge any hidden fees or surprise costs, and we always aim to
          complete the job within the agreed-upon timeline.
        </p>
        <p>
          At HandyMann, we are dedicated to making your life easier by providing
          reliable, trustworthy, and affordable handyman and artisan services.
          We are committed to delivering exceptional customer service and making
          sure that our clients and artisans are always satisfied with the work
          we do.
        </p>

        <p>
          Thank you for choosing HandyMann for your handyman and artisan needs.
          We look forward to serving you!
        </p>
      </div>
    </div>
  );
}

export default About;
