import React from "react";
// import { IoHome } from "react-icons/io";
import {
  GiAcousticMegaphone,
  GiGardeningShears,
  GiVacuumCleaner,
  GiStoneWall,
  GiGrass,
} from "react-icons/gi";
import {
  MdContentCopy,
  MdCameraOutdoor,
  MdComputer,
  MdDesignServices,
  MdElectricBolt,
  MdLandscape,
  MdPestControl,
  MdPlumbing,
  MdSecurity,
} from "react-icons/md";
import { TbAirConditioning } from "react-icons/tb";
import {
  FaClipboardList,
  FaGavel,
  FaLightbulb,
  FaMoneyBillAlt,
  FaPaintRoller,
  FaPalette,
  FaSwimmingPool,
  FaTree,
  FaTruckMoving,
  FaUsers,
  FaFacebookSquare,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
import { RiPaintBrushFill, RiWhatsappFill } from "react-icons/ri";

export const SERVICES_DATA = [
  {
    title: "Home Services",
    categories: [
      {
        name: "Cleaning Services",
        icon: <GiVacuumCleaner />,
        description:
          "Professional cleaning services for homes, including regular cleaning, deep cleaning, move-in/move-out cleaning, and more.",
      },
      {
        name: "Plumbing Services",
        icon: <MdPlumbing />,
        description:
          "Installation, repair, and maintenance of plumbing systems, including water supply, drainage, and sewage systems.",
      },
      {
        name: "Pest Control Services",
        icon: <MdPestControl />,
        description:
          "Treatment and prevention of pests in and around homes, including insects, rodents, and wildlife.",
      },
      {
        name: "Lawn and Garden Service",
        icon: <GiGardeningShears />,
        description:
          "Maintenance and improvement of outdoor spaces, including lawn care, landscaping, garden design, and more.",
      },
      {
        name: "HVAC Services",
        icon: <TbAirConditioning />,
        description:
          "Installation, repair, and maintenance of heating, ventilation, and air conditioning systems.",
      },
      {
        name: "Electrical Services",
        icon: <MdElectricBolt />,
        description:
          "Installation, repair, and maintenance of electrical systems, including wiring, outlets, lighting, and more.",
      },
      {
        name: "Home Security Services",
        icon: <MdCameraOutdoor />,
        description:
          "Installation and maintenance of security systems, including alarms, surveillance cameras, and access control systems.",
      },
      {
        name: "Moving Services",
        icon: <FaTruckMoving />,
        description:
          "Professional moving services for homes, including packing, loading, transportation, unloading, and unpacking.",
      },
      {
        name: "Interior Design Services",
        icon: <MdDesignServices />,
        description:
          "Design and decoration of interior spaces, including furniture selection, color schemes, and more.",
      },
      {
        name: "Painting and Decorating Services",
        icon: <FaPaintRoller />,
        description:
          "Interior and exterior painting, wallpapering, and other decorative services for homes.",
      },
    ],
  },
  {
    title: "Office Services",
    categories: [
      {
        name: "IT Support Services",
        icon: <MdComputer />,
        description:
          "Technical support for computer systems, software, and networks in the office.",
        items: [],
      },
      {
        name: "Cleaning Services",
        icon: <GiVacuumCleaner />,
        description:
          "Professional cleaning services for offices, including regular cleaning, deep cleaning, and more.",
        items: [],
      },
      {
        name: "Security Services",
        icon: <MdSecurity />,
        description:
          "Installation and maintenance of security systems, including access control, surveillance, and alarm systems.",
        items: [],
      },
      {
        name: "Accounting and Bookkeeping Services",
        icon: <FaMoneyBillAlt />,
        description:
          "Financial management services, including bookkeeping, payroll, tax preparation, and more.",
        items: [],
      },
      {
        name: "Office Supplies and Equipment Services",
        icon: <FaClipboardList />,
        description:
          "Provision and maintenance of office supplies and equipment, including printers, copiers, and office furniture.",
        items: [],
      },
      {
        name: "Marketing and Advertising Services",
        icon: <GiAcousticMegaphone />,
        description:
          "Promotion and marketing services for businesses, including digital marketing, advertising, and public relations.",
        items: [],
      },
      {
        name: "Human Resources Services",
        icon: <FaUsers />,
        description:
          "HR management services, including recruitment, employee training, performance management, and more.",
        items: [],
      },
      {
        name: "Legal Services",
        icon: <FaGavel />,
        description:
          "Legal advice and representation for businesses, including contract drafting, dispute resolution, and more.",
        items: [],
      },
      {
        name: "Graphic Design and Printing Services",
        icon: <FaPalette />,
        description:
          "Design and printing services for promotional materials, including business cards, flyers, and brochures",
      },
    ],
  },
  {
    title: "Outdoor Services",
    categories: [
      {
        name: "Landscaping Services",
        icon: <MdLandscape />,
        description:
          "Design and maintenance of outdoor spaces, including lawn care, tree and plant selection, and hardscaping.",
        items: ["Lawn care", "Tree and plant selection", "Hardscaping"],
      },
      {
        name: "Tree Services",
        icon: <FaTree />,
        description:
          "Tree trimming, pruning, and removal services, including stump grinding and emergency tree services.",
        items: [
          "Tree trimming",
          "Pruning",
          "Removal services",
          "Stump grinding",
        ],
      },
      {
        name: "Lawn Maintenance Services",
        icon: <GiGrass />,
        description:
          "Regular maintenance services for lawns, including mowing, fertilization, and weed control.",
        items: ["Mowing", "Fertilization", "Weed control"],
      },
      {
        name: "Pool Maintenance and Repair Services",
        icon: <FaSwimmingPool />,
        description:
          "Maintenance and repair services for pools and spas, including cleaning, chemical treatments, and equipment repair.",
        items: ["Cleaning", "Chemical treatments", "Equipment repair"],
      },
      {
        name: "Exterior Painting Services",
        icon: <RiPaintBrushFill />,
        description:
          "Painting and staining of exterior surfaces, including siding, trim, and fences.",
        items: ["Painting", "Staining"],
      },
      {
        name: "Outdoor Lighting Services",
        icon: <FaLightbulb />,
        description:
          "Installation and maintenance of outdoor lighting systems, including landscape lighting and security lighting.",
        items: [
          "Installation",
          "Maintenance",
          "Landscape lighting",
          "Security lighting",
        ],
      },
      {
        name: "Hardscaping Services",
        icon: <GiStoneWall />,
        description:
          "Design and installation of outdoor hardscaping features, including patios, walkways, and retaining walls.",
        items: [
          "Design",
          "Installation",
          "Patios",
          "Walkways",
          "Retaining walls",
        ],
      },
    ],
  },
];

export const FAQ_DATA = [
  {
    question: "What is HandyMann?",
    answer:
      "HandyMann is a mobile app that connects users with skilled and experienced handymen for a variety of home repair and maintenance tasks.",
  },
  {
    question: "How does HandyMann work?",
    answer:
      "Users can browse handymen profiles, read reviews, and get quotes for their projects. Once they select a handyman, they can schedule an appointment and pay for the service through the app.",
  },
  {
    question: "Who are the handymen on HandyMann?",
    answer:
      "All handymen on HandyMann are vetted and screened to ensure that they have the skills and experience necessary to provide high-quality service to our users.",
  },
  {
    question: "What types of services do handymen on HandyMann offer?",
    answer: `Handymen on HandyMann can offer a wide variety of services, including plumbing, electrical, carpentry, painting, and more. Kindly visit 
      Our Services page for more.`,
  },
  {
    question: "Is HandyMann available in my area?",
    answer:
      "We're working hard to expand our service area, but currently, HandyMann is only available in Accra, Kumasi, Takoradi, and Ho.",
  },
  {
    question: "How do I pay for HandyMann services?",
    answer:
      "Users can pay for services through the app using a credit card or other accepted payment methods. Payment is processed securely through the app.",
  },
  {
    question: "What if I'm not satisfied with the service provided?",
    answer:
      "We take user satisfaction very seriously, and we have a process in place for resolving any issues that may arise. Users can contact us through the app to report any problems with the service they received.",
  },
  {
    question: "How does HandyMann ensure user safety?",
    answer:
      "HandyMann takes user safety very seriously and has implemented a variety of measures to ensure that users are protected. These measures include background checks on all handymen, secure payment processing, and user ratings and reviews.",
  },
  {
    question: "How do I become a HandyMann handyman?",
    answer:
      "Handymen can apply to join the HandyMann platform through our website. We have a rigorous screening process to ensure that all handymen meet our standards for quality and reliability.",
  },
  {
    question: "How can I contact HandyMann?",
    answer:
      "Users can contact us through the app or through our website. We're always happy to answer any questions or concerns that users may have.",
  },
];

const shareText =
  "HandyMann is your one-stop shop for all your Home, Office, and Outdoor repairs and maintenance work. Join our waiting list and be the first to be updated on latest developments. https://www.handymann.org";

export const LINKS = [
  {
    name: "Share on Facebook",
    baseApi:
      "https://www.facebook.com/sharer/sharer.php?u=" +
      encodeURIComponent(window.location.href) +
      "&quote=",
    url: shareText,
    icon: <FaFacebookSquare />,
  },
  {
    name: "Share on Twitter",
    baseApi: "https://twitter.com/intent/tweet?text=",
    url: shareText,
    icon: <FaTwitter />,
  },
  {
    name: "Share on LinkedIn",
    baseApi:
      "https://www.linkedin.com/shareArticle?mini=true&url=" +
      encodeURIComponent(window.location.href) +
      "&title=",
    url: shareText,
    icon: <FaLinkedin />,
  },
  {
    name: "Share on Whatsapp",
    baseApi: "https://wa.me/?text=",
    url: shareText,
    icon: <RiWhatsappFill />,
  },
  { name: "Copy Link", url: shareText, icon: <MdContentCopy /> },
];
