// is required
const isRequired = (value) => (value === "" ? false : true);

// isBetween
const isBetween = (length, min, max) =>
  length < min || length > max ? false : true;

// isEmailValid
const isEmailValid = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

// check phone number
function checkPhoneNumber(number) {
  var pattern = /^[0-9]{10}$/;
  if (!pattern.test(number)) {
    return false;
  }
  return true;
}

// isPasswordSecure
// const isPasswordSecure = (password, expression) => {
//   const re = new RegExp(`^${expression}`);
//   return re.test(password);
// };

// showError
// const showError = (input, message) => {
//   // get the form-field element
//   const formField = input.parentElement;
//   // add the error class
//   formField.classList.remove("success");
//   formField.classList.add("shake");

//   // show the error message
//   const error = formField.querySelector("small");
//   error.textContent = message;
// };

// showSuccess
// const showSuccess = (input) => {
//   // get the form-field element
//   const formField = input.parentElement;

//   // remove the error class
//   formField.classList.remove("shake");
//   formField.classList.add("success");

//   // hide the error message
//   const error = formField.querySelector("small");
//   error.textContent = "";
// };

// check username
const checkUsername = (value, onError) => {
  //   let valid = false;
  const min = 6,
    max = 25;
  const username = value.trim();
  if (!isRequired(username)) {
    // showError(value, "Username required.")
    // Alert.alert('Input required!', 'Email is required!');
    return false;
  }
  if (!isBetween(username.length, min, max)) {
    // Alert.alert(
    //   'Invalid Input',
    //   `Username must be between ${min} and ${max} characters.`,
    // );
    return false;
  }
  if (isRequired(username) && isBetween(username.length, min, max)) {
    // Alert.alert('');
    return true;
  }
};

// check email
const checkEmail = (value, onError) => {
  //   let valid = false;
  const email = value.trim();
  if (!isRequired(email)) {
    alert(
      "Email required! Kindly enter a valid email to join the waiting list!"
    );
    return false;
  }
  if (!isEmailValid(email)) {
    alert(
      "Email is not valid! Kindly enter a valid email to join the waiting list!"
    );
    return false;
  }
  if (isRequired(email) && isEmailValid(email)) {
    return true;
  }
};

export { checkUsername, checkEmail, checkPhoneNumber };

// password check
// `<span style={{color: ${
//     isPasswordSecure(password, "(?=.{8,})") && "var(--green)"
//   } }}>Password must has at least 8 characters that</span> <span  style={{color: ${
//     isPasswordSecure(password, "(?=.*[a-z])") && "var(--green)"
//   } }}>include at least 1 lowercase character</span>, <span  style={{color: ${
//     isPasswordSecure(password, "(?=.*[A-Z])") && "var(--green)"
//   } }}>1 uppercase characters</span>, <span  style={{color: ${
//     isPasswordSecure(password, "(?=.*[0-9])") && "var(--green)"
//   } }}>1 number</span>, <span  style={{color: ${
//     isPasswordSecure(password, "(?=.*[!@#$%^&*])") && "var(--green)"
//   } }}> and 1 special character in (!@#$%^&*)</span>`
