import React from "react";
import { FaFacebookSquare, FaLinkedin, FaTwitter } from "react-icons/fa";
import { BsAndroid2, BsApple } from "react-icons/bs";
import Button from "../button/Button";
import "./styles.css";
import { useNavigate } from "react-router-dom";

const socials = [
  // { name: "facebook", url: "facebook.com", icon: <FaFacebookSquare /> },
  {
    name: "Follow us on Twitter",
    url: "https://twitter.com/HandyMann_Ghana",
    icon: <FaTwitter />,
  },
  {
    name: "Follow us on LinkedIn",
    url: "https://www.linkedin.com/in/handymann-ghana-9a248a26b/",
    icon: <FaLinkedin />,
  },
];

function Footer() {
  const navigate = useNavigate();

  function handleNavigate(to) {
    navigate(to);
  }

  return (
    <div id="footer" className="wrapper full">
      <div className="wrapper auto footer">
        <div className="top flex justify-between gap-3">
          <div className="flex gap-0 left">
            <h3 className="logo-text">HandyMann</h3>
            <ul className="flex gap-2 links">
              <li
                className="pointer"
                onClick={() => handleNavigate("/policies/privacy-policy")}
              >
                Privacy Policy
              </li>
              <li
                className="pointer"
                onClick={() => handleNavigate("/policies/terms-of-service")}
              >
                Terms and Conditions
              </li>
            </ul>
          </div>
          <div className="flex gap-1 right">
            <Button
              text="Download on Android"
              supportText="Coming Soon"
              icon={<BsAndroid2 />}
              styles={{
                flexDirection: "row-reverse",
                gap: "10px",
                cursor: "not-allowed",
              }}
            />
            <Button
              text="Download on iOS"
              supportText="Coming Soon"
              icon={<BsApple />}
              styles={{
                flexDirection: "row-reverse",
                gap: "10px",
                cursor: "not-allowed",
              }}
            />
          </div>
        </div>
        <div className="flex justify-between gap-2 bottom">
          <p className="copy">Site design / logo &copy; 2023 HandyMann.</p>
          <div className="socials flex gap-2">
            {socials.map((item, index) => {
              return (
                <Button
                  key={index}
                  className={"icon-btn"}
                  icon={item.icon}
                  iconStyles={{ fontSize: "25px" }}
                  title={item.name}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
