import React from "react";

function Button({
  icon,
  text,
  title,
  supportText,
  className,
  styles,
  iconStyles,
  onClick,
  disabled,
}) {
  return (
    <div
      className={`btn bg-primary ${className} ${
        disabled ? "bg-slate-300" : ""
      }`}
      style={styles}
      title={title}
      onClick={disabled ? () => null : onClick}
    >
      <div>
        <div>{text}</div>{" "}
        {supportText && <div className="small-text">{supportText}</div>}
      </div>
      {icon && (
        <div className="flex icon" style={iconStyles}>
          {icon}
        </div>
      )}
    </div>
  );
}

export default Button;
