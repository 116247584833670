import { createBrowserRouter } from "react-router-dom";
import About from "../pages/About/About";
import Page404 from "../pages/404/Page404";
import App from "../App";
import Services from "../pages/services/Services";
import Updates from "../pages/Updates/Updates";
import FAQ from "../pages/FAQ/FAQ";
import PrivacyPolicy from "../pages/legal/PrivacyPolicy";
import TermsOfService from "../pages/legal/TermsOfServices";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Page404 title="404 - Page Not Found | HandyMann" />,
    children: [
      {
        path: "/",
        element: <About title="About Us | HandyMann" />,
      },
      {
        path: "/services",
        element: <Services title="Our Services | HandyMann" />,
      },
      {
        path: "/updates",
        element: <Updates title="Updates | HandyMann" />,
      },
      {
        path: "/faq",
        element: <FAQ title="FAQ - Frequently Asked Questions| Handymann" />,
      },
      {
        path: "/policies/privacy-policy",
        element: <PrivacyPolicy title="Privacy Policy | HandyMann Legal" />,
      },
      {
        path: "/policies/terms-of-service",
        element: <TermsOfService title="Terms Of Services | HandyMann Legal" />,
      },
    ],
  },
]);
