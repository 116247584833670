import React from "react";
import "./styles.css";

function ServicesListItem({ icon, title, description }) {
  return (
    <div className="service-list-card">
      <div className="flex icon">{icon}</div>
      <div className="content">
        <p className="card-title">{title}</p>
        <p className="desc">{description}</p>
      </div>
    </div>
  );
}

export default ServicesListItem;
